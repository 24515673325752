@import url(https://fonts.googleapis.com/css?family=Oswald);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  font-size: 62.5%;
  background-color: #000;
}

h1 {
  color: black;
}

canvas {
  background: #000;
  position: fixed;
  padding-top: 0;
}

main {
  position: relative;
  top: 30%;
  color: #FFF;
}

/* Classes */
.svg-size {
   width: 8em;
   height: 8em;
}

.aws-certs {
  width: 100px;
}

.not-sticky {
  position: absolute;
  top: 100%;
}

.sticky {
  position: fixed;
  top: 0;
}

.Content {
  padding-top: 40%;
}

/* IDs */
#header {
  position: absolute;
  padding: 1em;

  top: 30%;
  left: 20%;
  right: 20%;

  background-color: rgba(0, 0, 0, 0.5);
}

#chevron {
  position: absolute;

  top: 90%;
  left: 40%;
  right: 40%;

  text-align: center;
  font-size: 8em;
  color: white;
}

#header h1,
#header h2 {
  color: white;
  text-align: center;
  font-family: 'Oswald', sans-serif;
}

#header h1 {
    font-size: 10em;
    padding-bottom: 0.25em;
}

#header h2 {
    font-size: 6em;
}

#social-icons {
  text-align: center;
  padding-top: 3%;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 60px;
}

#social-icons a {
  padding-left: 5px;
  padding-right: 5px;
}

#social-icons a:link,
#social-icons a:visited,
#social-icons a:active,
#social-icons a:hover{
  text-decoration: none;
  color: #FFF;
}

#digital-badges {
  padding-top: 20px;
  text-align: center;
}

#navbar {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;

  font-size: 50px;

  height: 10%;
  width: 100%;

  font-family: 'Oswald', sans-serif;
}


